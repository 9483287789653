import { LinkData, MediaFile } from "@graphql/graphql";
import { getUserLocation } from "@shared/helpers";
import { convertHTMLTextToCorrectText } from "@shared/helpers/convert-html-text-to-correct-text";
import { FormState } from "@shared/hooks";
import { citiesStore, postListStore, toastsStore } from "@store";

export async function createNewPost(
  formState: FormState<{text: string; media: MediaFile[], linkIds: LinkData[]}>,
  setInitialFormSate: () => void,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  try {
    setLoading(true);

    const location = await getUserLocation();
    const lat = location ? location.latitude : null;
    const lng = location ? location.longitude : null;

    const newPost = { ...formState };

    await postListStore.createPost({
      text: convertHTMLTextToCorrectText(newPost.text),
      cityId: citiesStore.currentCity?.place_id,
      lat,
      lng,
      media: formState.media,
      linkDataId: formState.linkIds.filter(Boolean).map((link: LinkData) => link.id),
    })
    setInitialFormSate();
    setLoading(false);
  } catch (err) {
    toastsStore.addErrorToast('Can not create the new post!');
    setLoading(false);
  }
}