export const requestPermission = async () => {
  if (!("Notification" in window)) {
    console.error('Notification is not supported!')
    return;
  }
  
  const permission =
    window.Notification?.requestPermission 
    && await window.Notification?.requestPermission();

  if (permission !== "granted") {
    console.error("Permission not granted for Notification!");
    return;
  }
};

export const showNotification = async (title: string, options?: NotificationOptions) => {
  if (!("Notification" in window)) {
    return null;
  }

  if (window.Notification?.permission !== "granted") {
    return null;
  }

  return new window.Notification(title, options);
};
