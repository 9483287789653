import styles from "./main.module.css";
import { Header } from "@shared/components/header/header.comp";
import { userStore } from "@store";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { TabItem, Tabs } from "@ui-kit/components/tabs/tabs.comp";
import { useMainTabs } from "./data/useMainTabs";
import { CityCover } from "./components/city-cover/city-cover.comp";
import { CreatePostComponent } from "./components/create-post/create-post.component";
import { BottomMenu } from "@ui-kit/components/bottom-menu/bottom-menu.comp";
import { UserMenu } from "@shared/components/user-menu/user-menu.comp";
import { useLocation, useNavigate } from "react-router-dom";
import { PostList } from "./components/post-list/post-list.comp";
import { FragmentWindow } from "@shared/components/fragment-window/fragment-window.comp";
import { SES_STOR_REG_CITY } from "src/auth-pages/pages/registration-page/registration.page";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { useTranslation } from "react-i18next";

type Props = {
  onOverlay: (overlay: boolean) => void,
}

export const MainPage = observer(({onOverlay}: Props) => {
  const { currentUser } = userStore;
  const mainTabs = useMainTabs();
  const { t } = useTranslation('main');

  const [curentTab, setCurrentTab] = useState<TabItem|undefined>(mainTabs[0]);
  const userMenuState = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);

  const navigate = useNavigate();

  let { search } = useLocation();
  if (search.substring(0, 7) === '?token=') {
    userStore.me(search?.substring(7) ?? '');
  }

  useEffect(() => {
    if(currentUser && !currentUser.cityId) {
      const cityId = sessionStorage.getItem(SES_STOR_REG_CITY);
      sessionStorage.removeItem(SES_STOR_REG_CITY);

      if (!cityId) {
        return navigate('/map/select')
      }

      userStore.addUserCity(cityId);
    }
  }, [currentUser])

  return (<>
      <OgMetaData type='main' data={t('main.header')} />
      <FragmentWindow isShow={showCreatePost} onHide={() => setShowCreatePost(false)}>
        <CreatePostComponent className={styles['create-post-mobile']} showOnMobile onSendSucces={() => setShowCreatePost(false)} />
      </FragmentWindow>
        <div className={styles['main-right']}>
          <Header
            isMainPage={true}
            title={t('main.title')}
            userMenuState={userMenuState}
          />
          {!!currentUser && <Tabs
            className={styles.tabs}
            items={mainTabs}
            current={curentTab}
            onClick={(key: string) => setCurrentTab(mainTabs.find((tab) => tab.key === key))}
          />}
          <CityCover />
          <CreatePostComponent />
          <PostList selectedTab={curentTab?.key === 'read' ? 'follow' : 'all'} />
        </div>
    <BottomMenu
      className={styles['bottom-menu']}
      onCreateClick={() => setShowCreatePost(true)}/>
    <UserMenu userMenuState={userMenuState} onOverlay={onOverlay} user={userStore.currentUser} />
  </>);
});
