import { gql } from "@apollo/client";

export const upload = gql`query upload($input: UploadInput) {
    upload(input: $input){
        url
        mediaFile{
            url
            key
            type
            converted
        }
    }
}`;
