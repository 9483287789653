import styles from "./registration.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { toastsStore, modalCustomDialogsStore, registrationStore } from "@store";
import { AuthWindow, Button, InputFormComponent, LinkComponent } from "@ui-kit";
import { GoogleButton, FacebookButton, AppleButton } from "@ui-kit/components/button/social-buttons/";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useValidationRules } from "@ui-kit/components/input/helpers/useValidationRules";
import { City, CreateCityInput } from "@graphql/graphql";
import { RegistrationMap } from "../../components/registration-map/registration-map.component";
import { onGoogleClick } from "@shared/helpers/on-google-click";
import { UserAgreementComponent } from "../../../agreement-page/user-agreement.comp";
import { OgMetaData } from "@shared/components/og-meta-data/og-meta-data.comp";
import { useTranslation } from "react-i18next";

export const SES_STOR_REG_CITY = 'regCityPlaceId';

export interface NewUser {
  firstname: string,
  lastname: string,
  username: string,
  phone: string;
  password: string,
  passwordRepeat: string,
}

const ENABLED_OAUTH = process.env.REACT_APP_DISABLE_OAUTH?.toLowerCase() !== 'true';

const RegistrationPage = observer(() => {
  const [city, setCity] = useState<City|null>(null);

  const { handleSubmit, control, formState: { errors, isValid } } = useForm<NewUser>({
    mode: 'onBlur',
    defaultValues:  registrationStore.getData() as NewUser,
  });

  const validationRules = useValidationRules();
  const { t } = useTranslation('authorization');
  const { showDialog } = modalCustomDialogsStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (city?.place_id) {
      sessionStorage.setItem(SES_STOR_REG_CITY, city?.place_id);

      const newCity: CreateCityInput = {
        name: city.name ?? '',
        country: city.country ?? '',
        longName: city.longName ?? '',
        picture: city.picture,
        place_id: city.place_id
      }

      registrationStore.setCity(newCity);

      } else {
        sessionStorage.removeItem(SES_STOR_REG_CITY);
        registrationStore.setCity(undefined);
      }
  }, [city]);


  const formSubmitHandler: SubmitHandler<NewUser> = (data: NewUser) => {
    if (data.password !== data.passwordRepeat) {
      toastsStore.addErrorToast(t('toasts.error.password'));
      return;
    }
    Object.entries(data).forEach(([key, value]) => {
      registrationStore.setData(key as keyof NewUser, value);
    })
    navigate('/registration/verify');
  }

  const showUserAgreement = () => {
    modalCustomDialogsStore.hideDialog();
    return showDialog(
      <UserAgreementComponent isModal={true}/>,
      {
        title: t('modal.titleAgree'),
      }
    );
  }

  return (
    <>
      <OgMetaData type='main' data={t('register.header')} />
      {!city && <AuthWindow pageType='registration-map'><RegistrationMap selectCity={setCity}/></AuthWindow>}
      {city &&
        <AuthWindow pageType='registration'>
          <h2 className={styles.title}>{t('register.title')}</h2>
          <h3 className={styles.subtitle}>
          {t('register.subtitle')}
          </h3>
          {ENABLED_OAUTH && <div className={styles.socialButtonsBlock}>
            <GoogleButton btnSmall={true} supportingText={false} onClick={onGoogleClick}></GoogleButton>
            <FacebookButton btnSmall={true} supportingText={false}></FacebookButton>
            <AppleButton btnSmall={true} supportingText={false}></AppleButton>
          </div>}
          <form className={styles['form-registr']} onSubmit={handleSubmit(formSubmitHandler)}>
            <div className={styles.usernameBlock}>
              <Controller
                control={control}
                name='firstname'
                rules={validationRules.firstname}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputFormComponent
                    label={t('input.firstname.label')}
                    placeholder={t('input.firstname.placeholder')}
                    type='text'
                    name='firstname'
                    value={value}
                    control={control}
                    onChange={onChange}
                    onBlur={onBlur}
                    hasErrors={!!errors.firstname}
                    errorsMess={errors.firstname?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name='lastname'
                rules={validationRules.lastname}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputFormComponent
                    label={t('input.lastname.label')}
                    placeholder={t('input.lastname.placeholder')}
                    type='text'
                    name='lastname'
                    value={value}
                    control={control}
                    onChange={onChange}
                    onBlur={onBlur}
                    hasErrors={!!errors.lastname}
                    errorsMess={errors.lastname?.message}
                  />
                )}
              />
            </div>
            <div className={styles.inputBlock}>
            <Controller
              control={control}
              name='username'
              rules={validationRules.username}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputFormComponent
                  label={t('input.username.label')}
                  placeholder={t('input.username.placeholder')}
                  type='text'
                  name='username'
                  value={value}
                  control={control}
                  onChange={onChange}
                  onBlur={onBlur}
                  supportingText={t('input.username.supportText')}
                  hasErrors={!!errors.username}
                  errorsMess={errors.username?.message}
                  tips={t('input.username.tips')}
                />
              )}
            />
            <Controller
              control={control}
              name='phone'
              rules={validationRules.phone}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputFormComponent
                  label={t('input.phone.label')}
                  placeholder={t('input.phone.placeholder')}
                  type='phone'
                  name='phone'
                  value={value}
                  control={control}
                  onChange={onChange}
                  onBlur={onBlur}
                  hasErrors={!!errors.phone}
                  errorsMess={errors.phone?.message}
                />
              )}
            />
            <Controller
              control={control}
              name='password'
              rules={validationRules.password}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputFormComponent
                  label={t('input.password.label')}
                  placeholder={t('input.password.placeholder')}
                  type='password'
                  name='password'
                  value={value}
                  control={control}
                  onChange={onChange}
                  onBlur={onBlur}
                  supportingText={t('input.password.supportText')}
                  hasErrors={!!errors.password}
                  errorsMess={errors.password?.message}
                />
              )}
            />
            <Controller
              control={control}
              name='passwordRepeat'
              rules={validationRules.passwordRepeat}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputFormComponent
                  label={t('input.repeatPassword.label')}
                  placeholder={t('input.repeatPassword.placeholder')}
                  type='password'
                  name='passwordRepeat'
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  control={control}
                  hasErrors={!!errors.passwordRepeat}
                  errorsMess={errors.passwordRepeat?.message}
                />
              )}
            />
            </div>
            <Button disabled={!isValid} type='submit'>{t('register.button.continue')}</Button>
          </form>
          <p className={styles.userAgreementBlock}>
            <LinkComponent typeLink='gray-underline' click={showUserAgreement}>
              <span>{t('link.agreement')}</span>
            </LinkComponent>
          </p>
          <p className={styles.bottomWindowBlock}>
            <span>{t('link.haveAccount')}</span>
            <LinkComponent link='/login'>{t('register.button.enter')}</LinkComponent>
          </p>
      </AuthWindow>}
    </>
  );
});

export default RegistrationPage;