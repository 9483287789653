import styles from "./constnt.module.css";
import { useLocation } from "react-router-dom";
import { FULL_WIDTH_PATHS } from "./fullWidthPaths";

type Props = {
  className?: string,
  children: any,
}

export const Content = ({ className, children }: Props) => {

  const location = useLocation();
  const isFullWidth = FULL_WIDTH_PATHS.includes(location.pathname.split('/')?.[1])

  className = className ?? '';

  return <div className={`${styles.content} ${className} ${isFullWidth ? styles['content-full-width'] : ''}`}>
    {children}
  </div>
}