import { makeAutoObservable } from 'mobx';
import { CreateCityInput } from '@graphql/graphql';

interface RegistrationData {
  firstname: string;
  lastname: string;
  username: string;
  phone: string;
  password: string;
  passwordRepeat: string;
  whatsapp: boolean;
}

class RegistrationStore {
  data: RegistrationData = {
    firstname: '',
    lastname: '',
    username: '',
    phone: '',
    password: '',
    passwordRepeat: '',
    whatsapp: false,
  };

  city?: CreateCityInput;

  constructor() {
    makeAutoObservable(this);
  }

  setData<Key extends keyof RegistrationData>(key: Key, value: RegistrationData[Key]) {
    this.data[key] = value;
  }

  getData() {
    return this.data;
  }

  reset() {
    Object.assign(this.data, {
      firstname: '',
      lastname: '',
      username: '',
      phone: '',
      password: '',
      passwordRepeat: '',
      whatsapp: false,
    });
    this.city = undefined;
  }

  setCity(city: CreateCityInput | undefined) {
    this.city = city;
  }

  getCity() {
    return this.city;
  }
}

export const registrationStore = new RegistrationStore();