import { MediaFile } from "@graphql/graphql";
import styles from "./media-item.module.css";
import ReactPlayer from "react-player";
import ConvertingVideo from "../converting-video/converting-video.comp";
import { useEffect, useState } from "react";
import { set } from "mobx";
import { postListStore } from "@store";

type Props = {
  mediaFile: MediaFile,
  className?: string,
  width?: number,
  height?: number,
  controls?: boolean,
  onClick?: () => void,
}

export const MediaItemComponent = ({ mediaFile, className, width, height, controls, onClick }: Props) => {
  let classNameUnion = className ? `${styles['post-media']} ${className}` : styles['post-media'];
  controls = controls !== false

  classNameUnion += ` {width: ${width} !important; minheight: ${height} !important;}`
  const [converted, setConverted] = useState(false)

  useEffect(() => {
    if (!mediaFile.url || !mediaFile.type?.includes('video')) {
      return;
    }
    if (mediaFile.converted) {
      postListStore.fileIsConverted(mediaFile.url);
      setConverted(true);
    }

    postListStore.addFileToCheck(mediaFile.url);

    setInterval(() => {
      if (mediaFile.converted === false && mediaFile.url && !converted) {
        if (postListStore.checkFile(mediaFile.url)) {
          setConverted(true);
        }
      }
    }, 1000);
  }, [mediaFile])

  if(mediaFile.converted === false && !converted) return <ConvertingVideo className={classNameUnion} />

  return <>
    {mediaFile?.type?.includes('image') ? (
      <div
        onClick={() => onClick && onClick()}
        style={{
          backgroundImage: `url('${mediaFile?.url}')`,
          cursor: !!onClick ? 'pointer' : 'default',
          minHeight: `${height}px`,
        }}
        className={classNameUnion}
        />
    ) : (
      <ReactPlayer
        width={width}
        height={height}
        onClick={() => onClick && onClick()}
        style={{
          cursor: !!onClick ? 'pointer' : 'default',
          height: '100% !important',
        }}
        className={classNameUnion}
        controls={controls}
        url={mediaFile?.url ?? ''}
      />)}
  </>
}