import { useEffect, useState } from 'react';
import styles from './dropdown-user-list.module.css';
import { DropdownInput } from '../../../ecitty-ui/components/input/dropdown-input/dropdown-input';
import { SEARCH_MIN_LENGTH, SEARCH_TIMEOUT_MS } from '@shared/data';
import { SearchResults, UserPicture } from '@graphql/graphql';
import { searchUsers } from 'src/messenger-page/helpers/search-users';
import { userToMenuItem } from 'src/messenger-page/helpers/user-to-menu-item';
import { userStore } from '@store';
import { Loader } from '@ui-kit';

let timeoutFn: any;

type Props = {
  text: string,
  setText: React.Dispatch<React.SetStateAction<string>>,
  onSelect?: (item: UserPicture) => void,
  className?: string,
  top?: number
  left?: number
}

export const DropdownUserList = ({
  onSelect, className, top, left, text: textProp, setText: setTextProp,
}: Props) => {
  const [users, setUsers] = useState<UserPicture[]>([]);
  const [currentUser, setCurrentUser] = useState<UserPicture>();
  const [text, setText] = useState(textProp);
  const [loading, setLoading] = useState(false);

  useEffect(() => setTextProp(text), [text]);
  useEffect(() => setText(textProp), [textProp]);

  useEffect(() => {
    if (text === '' || text.length < SEARCH_MIN_LENGTH) {
      return;
    }
    setLoading(true);
    if(!!timeoutFn) {
      clearTimeout(timeoutFn)
    };
    timeoutFn = setTimeout(() => {
      searchUsers(text)
        .then((res: SearchResults) => {
          setUsers(() => res.userItems as UserPicture[]);
          setLoading(false);
        });
    }, SEARCH_TIMEOUT_MS);
  }, [text]);

  useEffect(() => onSelect && currentUser && onSelect(currentUser), [currentUser]);

  return <div
    className={className ? `${className} ${styles['dropdown-user-list']}` : styles['dropdown-user-list']}
    style={{ top, left }}
  >
    {loading
      ? <div className={styles['dropdown-user-list__loader']}>
        <Loader loaderSize='xs' />
      </div>
      : <DropdownInput
          label="Поиск"
          onchange={setText}
          text={text}
          items={
            users
              .filter((user) => user.username !== userStore.currentUser?.username)
              .map((user) => userToMenuItem(user, currentUser))
          }
          selecteItem={(item) => {
            setCurrentUser(users.find((user) => user.username === item.key));
          }}
          item={currentUser ? userToMenuItem(currentUser) : undefined}
          type='user'
          hideInput={true}
        />
    }
  </div>
}