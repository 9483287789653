import { FC, useEffect, useRef, useState } from 'react';
import styles from './converting-video.module.css'
import { useTranslation } from 'react-i18next';

const ConvertingVideo: FC<{className: string}> = ({ className }) => {
  const { t } = useTranslation('main');

  const element = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [lines, setLines] = useState<number[]>([]);
  const countSteps = useRef<number>(0);
  const animationFrame = useRef<number | null>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (element.current) {
        const newWidth = element.current.clientWidth;
        const newHeight = element.current.clientHeight;

        setWidth(newWidth);
        setHeight(newHeight);

        const count = Math.round(Math.max(newWidth, newHeight) * 1.4 / 16);
        setLines(new Array(count).fill(0).map((_, i) => i * 16));
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    const step = () => {
      countSteps.current += 1;

      if (countSteps.current < 16) {
        setLines((prevLines) => prevLines.map((line) => line + 1));
      } else {
        countSteps.current = 0;
        setLines((prev) => prev.map((_, i) => i * 16));
      }

      animationFrame.current = requestAnimationFrame(step);
    };

    animationFrame.current = requestAnimationFrame(step);

    return () => {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
    };
  }, [lines.length]);

  return (
    <div className={`${className} ${styles.container}`} ref={element}>
      {lines.map((line, i) => (
        <div className={styles.line} key={i} style={{
          height: `${height * 1.4}px`,
          top: `-${height * 0.2}px`,
          left: `${line - width * 0.2}px`,
        }} />
      ))}
      <h5 className={styles.text}>{t('video.converting')}</h5>
    </div>
  );
};

export default ConvertingVideo;