import { gql } from "@apollo/client";

export const switchOnNotifications = gql`mutation switchOnNotifications($chatId: String) {
    switchOnNotifications(chatId: $chatId){
        chatId
        username
        usernames
        admins
        moders
        title
        isChat
        isPublic
        media{
            url
            key
            type
            converted
        }
        userPicture{
            username
            firstname
            lastname
            gender
            dateOfBirth
            picture{
                url
                key
                type
                converted
            }
            cityId
            city{
                id
                place_id
                name
                username
                picture{
                    url
                    key
                    type
                    converted
                }
                longName
                country
                langs{
                    lang
                    name
                    country
                }
                utc_offset
                createdAt
                updatedAt
            }
            following
            followers
            roles{
                id
                name
                title
                type
                color
                textColor
                isVisible
            }
            postsLength
            isPremiun
            blockedYou
            isBlocked
            isFollow
            isOnline
            onlineSince
            isFollowing
            email
            banned
            warnings{
                id
                post{
                    id
                    text
                    username
                    city{
                        id
                        place_id
                        name
                        username
                        picture{
                            url
                            key
                            type
                            converted
                        }
                        longName
                        country
                        langs{
                            lang
                            name
                            country
                        }
                        utc_offset
                        createdAt
                        updatedAt
                    }
                    reactions{
                        id
                        postId
                        type
                        username
                        isHiden
                        createdAt
                        updatedAt
                    }
                    commentsLength
                    media{
                        url
                        key
                        type
                        converted
                    }
                    lat
                    lng
                    view
                    isSaved
                    createdAt
                    updatedAt
                    token
                    isHiden
                    follow
                    following
                    linkData{
                        id
                        url
                        title
                        text
                        image{
                            url
                            height
                            width
                            type
                        }
                        video{
                            url
                            height
                            width
                            type
                        }
                    }
                    comment{
                        id
                        postId
                        commentId
                        answer
                        username
                        text
                        media{
                            url
                            key
                            type
                            converted
                        }
                        reactions{
                            id
                            postId
                            type
                            username
                            isHiden
                            createdAt
                            updatedAt
                        }
                        commentsLength
                        follow
                        following
                        isHiden
                        createdAt
                        updatedAt
                    }
                    subComment{
                        id
                        postId
                        commentId
                        answer
                        username
                        text
                        media{
                            url
                            key
                            type
                            converted
                        }
                        reactions{
                            id
                            postId
                            type
                            username
                            isHiden
                            createdAt
                            updatedAt
                        }
                        commentsLength
                        follow
                        following
                        isHiden
                        createdAt
                        updatedAt
                    }
                    answer
                }
                comment{
                    id
                    postId
                    commentId
                    answer
                    username
                    text
                    media{
                        url
                        key
                        type
                        converted
                    }
                    reactions{
                        id
                        postId
                        type
                        username
                        isHiden
                        createdAt
                        updatedAt
                    }
                    commentsLength
                    follow
                    following
                    isHiden
                    createdAt
                    updatedAt
                }
                message{
                    id
                    chatId
                    username
                    usernames
                    read
                    delivered
                    text
                    media{
                        url
                        key
                        type
                        converted
                    }
                    reactions{
                        id
                        postId
                        type
                        username
                        isHiden
                        createdAt
                        updatedAt
                    }
                    createdAt
                    updatedAt
                }
                username
                reporterUsername
                reasonType
                reason
                isHandled
                isApprooved
                decisionText
                decisionType
                createdAt
                updatedAt
            }
        }
        typing
        offNotifyUsernames
        lastMessage{
            id
            chatId
            username
            usernames
            userPicture{
                username
                firstname
                lastname
                gender
                dateOfBirth
                picture{
                    url
                    key
                    type
                    converted
                }
                cityId
                city{
                    id
                    place_id
                    name
                    username
                    picture{
                        url
                        key
                        type
                        converted
                    }
                    longName
                    country
                    langs{
                        lang
                        name
                        country
                    }
                    utc_offset
                    createdAt
                    updatedAt
                }
                following
                followers
                roles{
                    id
                    name
                    title
                    type
                    color
                    textColor
                    isVisible
                }
                postsLength
                isPremiun
                blockedYou
                isBlocked
                isFollow
                isOnline
                onlineSince
                isFollowing
                email
                banned
                warnings{
                    id
                    post{
                        id
                        text
                        username
                        city{
                            id
                            place_id
                            name
                            username
                            picture{
                                url
                                key
                                type
                                converted
                            }
                            longName
                            country
                            langs{
                                lang
                                name
                                country
                            }
                            utc_offset
                            createdAt
                            updatedAt
                        }
                        reactions{
                            id
                            postId
                            type
                            username
                            isHiden
                            createdAt
                            updatedAt
                        }
                        commentsLength
                        media{
                            url
                            key
                            type
                            converted
                        }
                        lat
                        lng
                        view
                        isSaved
                        createdAt
                        updatedAt
                        token
                        isHiden
                        follow
                        following
                        linkData{
                            id
                            url
                            title
                            text
                            image{
                                url
                                height
                                width
                                type
                            }
                            video{
                                url
                                height
                                width
                                type
                            }
                        }
                        comment{
                            id
                            postId
                            commentId
                            answer
                            username
                            text
                            media{
                                url
                                key
                                type
                                converted
                            }
                            reactions{
                                id
                                postId
                                type
                                username
                                isHiden
                                createdAt
                                updatedAt
                            }
                            commentsLength
                            follow
                            following
                            isHiden
                            createdAt
                            updatedAt
                        }
                        subComment{
                            id
                            postId
                            commentId
                            answer
                            username
                            text
                            media{
                                url
                                key
                                type
                                converted
                            }
                            reactions{
                                id
                                postId
                                type
                                username
                                isHiden
                                createdAt
                                updatedAt
                            }
                            commentsLength
                            follow
                            following
                            isHiden
                            createdAt
                            updatedAt
                        }
                        answer
                    }
                    comment{
                        id
                        postId
                        commentId
                        answer
                        username
                        text
                        media{
                            url
                            key
                            type
                            converted
                        }
                        reactions{
                            id
                            postId
                            type
                            username
                            isHiden
                            createdAt
                            updatedAt
                        }
                        commentsLength
                        follow
                        following
                        isHiden
                        createdAt
                        updatedAt
                    }
                    username
                    reporterUsername
                    reasonType
                    reason
                    isHandled
                    isApprooved
                    decisionText
                    decisionType
                    createdAt
                    updatedAt
                }
            }
            read
            delivered
            text
            media{
                url
                key
                type
                converted
            }
            reactions{
                id
                postId
                type
                username
                isHiden
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
        }
        newMessagesCount
        createdAt
        updatedAt
    }
}`;
