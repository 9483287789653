import { observer } from "mobx-react-lite";
import styles from "./message-notifications.module.css";
import { messageNotificationsStore } from "src/store/mesage-notifications.store";
import { Avatar } from "@ui-kit";
import { useNavigate } from "react-router-dom";
import { Message } from "@graphql/graphql";
import { ItemMenu } from "../item-menu/item-menu.comp";
import { useMessageMenu } from "src/messenger-page/helpers/use-message-menu";
import { messagesStore, userStore } from "@store";
import { useMessageMenuClick } from "src/messenger-page/helpers/use-message-menu-click";
import { useTranslation } from "react-i18next";

export const MessageNotifications = observer(() => {
  const navigate = useNavigate();
  const getMessageMenu = useMessageMenu();
  const onMessageMenuClick = useMessageMenuClick();

  const { notifyList, hideMessage, pauseHiding } = messageNotificationsStore;

  const { t } = useTranslation('common');

  const onMessageClick = (e: any, message: Message) => {
    const classList = [...e.target.classList];
    if (
      classList.includes(styles['message-notifications'])
      || classList.includes(styles['message-notifications__list'])
      || classList.includes(styles['message-notifications__message'])
      || classList.includes(styles['message-notifications__dot'])
      || classList.includes(styles['message-notifications__content'])
      || classList.includes(styles['message-notifications__fullname'])
      || classList.includes(styles['message-notifications__text'])
    ) {
      onClick(message);
    } else {
      pauseHiding(message.id ?? undefined);
    }
  }

  const onClick = (message: Message) => {
    if (message.chatId) {
      navigate(`/messages?chat=${message.chatId}`);
    } else {
      navigate('/messages');
    }

    if (message.id) {
      hideMessage(message.id);
    }
  }

  return <div className={styles['message-notifications']}>
    {notifyList.length > 0
      && <div className={styles['message-notifications__list']}>
        {notifyList.map((message) =>
          <div
            className={styles['message-notifications__message']}
            onClick={(e) => onMessageClick(e, message)}
          >
            <div className={styles['message-notifications__dot']} />
            <Avatar
              alt={message.username ?? 'avatar'}
              url={message.userPicture?.picture?.url}
              size='lg'
              statusIcon='online' />
            <div className={styles['message-notifications__content']}>
              <h3 className={styles['message-notifications__fullname']}>
                {message.userPicture?.firstname} {message.userPicture?.lastname}
              </h3>
              <p className={styles['message-notifications__text']}>
                {message.text ? message.text : t('message.notification.file')}
              </p>
            </div>
            <div className={styles['message-notifications__right-menu']}>
              <ItemMenu
                className={styles['message-notifications__more-menu']}
                toRight={true}
                items={getMessageMenu(
                  messagesStore.chats.find((chat) => chat.chatId === message.chatId),
                  userStore.currentUser?.username
                )}
                onClick={(item) => onMessageMenuClick(
                  item,
                  messagesStore.chats.find((chat) => chat.chatId === message.chatId)
                )}
                onClose={() => message.id && hideMessage(message.id)} />
            </div>
          </div>
        )}
      </div>}
  </div>
});
