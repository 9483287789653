import { makeAutoObservable, runInAction } from "mobx";
import { toastsStore } from "./toasts.store";
import {
  ChangePasswordInput,
  City,
  ConfirmInput,
  CreateUserInput,
  Follower,
  ForgotPasswordInput,
  LoginUserInput,
  UpdateUserInput,
  UserPicture,
  UserToken,
  ChangePhoneInput,
  ChangePhoneConfirmInput,
  ChangeEmailConfirmInput,
  ChangeEmailInput,
  ChangeExistsPasswordInput
} from "@graphql/graphql";
import { apolloClient, SESSION_STORAGE_JWT_TOKEN } from "src/apollo-client";
import { login, me, user, getUserFollowing, getUserFollowers, getUserFriends, users, getUserFollowingUsers, getUserFollowersUsers, signOut } from "@graphql/docs/queries";
import { addCityToList, changePassword, confirmUser, createUser, follow, forgotPassword, unfollow, updateUser, changePhone, changePhoneConfirm, changeEmail, changeEmailConfirm, changeExistsPassword, updateUserCity } from "@graphql/docs/mutations";
import { getI18n } from "react-i18next";
import { ApolloError } from "apollo-client";
import { BANNED_USER, CITY_LIMIT, CITY_TIMEOUT, USER_NOT_FOUND, WRONG_PASSWORD } from "@shared/data/messages.data";

export const LOCALSTORAGE_KEY_LANG = 'lang';

class UserStore {
  currentUser?: UserToken | null;
  loading = false;
  authRejected = false;
  jwtToken?: string;
  phone?: string;
  city?: City|null;

  constructor() {
    makeAutoObservable(this);
  }

  private setWsToken(wsToken: import("@graphql/graphql").Maybe<string> | undefined) {
    wsToken
      ? sessionStorage.setItem(SESSION_STORAGE_JWT_TOKEN, wsToken)
      : sessionStorage.removeItem(SESSION_STORAGE_JWT_TOKEN);
  }

  get t() {
    const i18n = getI18n();
    return i18n.isInitialized ? i18n.t.bind(i18n) : () => "";
  }

  getUserData = async (username: string): Promise<UserPicture|null|undefined> => {
    this.setLoading(true);
    try {
      const result = await apolloClient.query<{user: UserPicture}>({
        query: user,
        variables: { username }
      });
      return result.data.user;
    } catch(err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.loadData'));
    }
  }

  getAllUsers = async () => {
    try {
      const result = await apolloClient.query<{users: [UserPicture]}>({
        query: users,
      });
      return result.data.users;
    } catch(err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.loadData'));
    }
  }

  userLogin = async (input: LoginUserInput): Promise<UserToken|void> => {
    try {
      const result = await apolloClient.query<{login: UserToken}>({
        query: login,
        variables: { input }
      });

      const errors = result.errors;

      if (errors?.length && errors[0].message === WRONG_PASSWORD) {
        toastsStore.addErrorToast(this.t('common:toasts.error.wrongPass'));
        return;
      }
      else if (errors?.length && errors[0].message === BANNED_USER) {
        toastsStore.addErrorToast(this.t('common:toasts.error.banned'));
        return;
      }
      else if (errors?.length && errors[0].message === USER_NOT_FOUND) {
        toastsStore.addErrorToast(this.t('common:toasts.error.notFound'));
        return;
      }

      runInAction(() => {
        this.currentUser = result.data.login;
        this.authRejected = false;
        this.setWsToken(result.data.login.wsToken)
      })

      return result.data.login;
    } catch(err) {
      this.currentUser = null;
      this.authRejected = true;
      toastsStore.addErrorToast(this.t('common:toasts.error.login'));
      throw new Error('Login failed');
    }
  }

  me = async (token?: string): Promise<UserToken|null> => {
    try {
      const result = await apolloClient.query<{me: UserToken}>({
        query: me,
      });
      if (!result?.data?.me?.token) {
        if (!token) {
          this.signOut();
          await new Promise(() => {
            runInAction(() => {
              this.currentUser = null;
            });
          })

          return null;
        }
      }

      runInAction(() => {
        this.currentUser = result.data.me;
        this.authRejected = false;
        this.setWsToken(result.data.me.wsToken)
      });
      return result.data.me;
    } catch(err) {
      this.currentUser = null;
      this.authRejected = true;
      return null;
    }
  }

  newUser = async (input: CreateUserInput): Promise<Boolean> => {
    try {
      const createUserNode = createUser;
      await apolloClient.mutate<{createUserNode: CreateUserInput}>({
        mutation: createUserNode,
        variables: { input }
      });
      runInAction(() => {
        this.phone = input.phone;
      });
      return true;
    } catch (error: any) {
      console.error('Ошибка при создании пользователя:', error);

      if (error.graphQLErrors?.some((e: any) => e.message.includes('Phone in wait list'))) {
        toastsStore.addErrorToast(this.t('common:toasts.error.phoneInWaitList'));
        return false;
      }

      if (error.graphQLErrors?.some((e: any) => e.message.includes('User with this phone is already registered!'))) {
        toastsStore.addErrorToast(this.t('common:toasts.error.phoneIsAlreadyRegistr'));
        return false;
      }

      if (error.graphQLErrors) {
        console.error('GraphQL ошибки:', error.graphQLErrors);
      }

      toastsStore.addErrorToast(this.t('common:toasts.error.createUser'));
      return false;
    }
  }

  updateUserData = async (input: UpdateUserInput): Promise<UserPicture|null|undefined> => {
    try {
      const updateUserNode = updateUser;
      const result = await apolloClient.mutate<{updateUser: UserPicture}>({
        mutation: updateUserNode,
        variables: { input }
      });
      const currentUserCopy = {...this.currentUser};
      currentUserCopy.username = result.data?.updateUser?.username ?? currentUserCopy.username;
      currentUserCopy.firstname = result.data?.updateUser?.firstname ?? currentUserCopy.firstname;
      currentUserCopy.lastname = result.data?.updateUser?.lastname ?? currentUserCopy.lastname;
      currentUserCopy.gender = result.data?.updateUser?.gender ?? currentUserCopy.gender;
      currentUserCopy.dateOfBirth = result.data?.updateUser?.dateOfBirth ?? currentUserCopy.dateOfBirth;
      currentUserCopy.picture = result.data?.updateUser?.picture ?? currentUserCopy.picture;
      currentUserCopy.cityId = result.data?.updateUser?.cityId ?? currentUserCopy.cityId;
      runInAction(() => {
        this.currentUser = currentUserCopy;
      });
      return result.data?.updateUser;
    } catch(err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.updateUser'));
      return null;
    }
  }

  selectCityInList = async (cityId: string): Promise<City[]|null> => {
    try {
      const result = await apolloClient.mutate<{addCityToList: UserToken}>({
        mutation: addCityToList,
        variables: { cityId },
      });

      if (result.errors) {
        toastsStore.addErrorToast(result.errors?.[0].message);
      }

      runInAction(() => {
        const currentUserCopy = {...this.currentUser};
        currentUserCopy.cityList = result.data?.addCityToList?.cityList ?? currentUserCopy.cityList;
        this.currentUser = currentUserCopy;
      });
      return result.data?.addCityToList?.cityList?.filter(Boolean) as City[] ?? null;
    } catch(err) {
      if ((err as ApolloError)?.graphQLErrors?.[0]?.message) {
        if ((err as ApolloError)?.graphQLErrors?.[0]?.message === CITY_LIMIT) {
          toastsStore.addErrorToast(this.t('common:toasts.error.limitCity'));
        }
        if ((err as ApolloError)?.graphQLErrors?.[0]?.message === CITY_TIMEOUT) {
          toastsStore.addErrorToast(this.t('common:toasts.error.timeoutCity'));
        }
      } else {
        toastsStore.addErrorToast(this.t('common:toasts.error.updateCity'));
      }
      return null;
    }
  }

  confirmUser = async (input: ConfirmInput): Promise<UserToken|null|undefined> => {
    try {
      const result = await apolloClient.mutate<{confirmUser: UserToken}>({
        mutation: confirmUser,
        variables: { input },
      });
      runInAction(() => {
        this.currentUser = result.data?.confirmUser;
        this.authRejected = false;
      });
      return result.data?.confirmUser;
    } catch(err) {
      console.error('Ошибка при подтверждении пользователя:', err);
      this.currentUser = null;
      this.authRejected = true;
      toastsStore.addErrorToast(this.t('common:toasts.error.confirmUser'));
      return null
    }
  }

  forgotUserPassword = async (input: ForgotPasswordInput): Promise<UserPicture|null|undefined> => {
    try {
      const forgotPasswordNode = forgotPassword;
      const result = await apolloClient.mutate<{forgotPassword: UserPicture}>({
        mutation: forgotPasswordNode,
        variables: { input },
      });
      return result.data?.forgotPassword;
    } catch (error: any) {
      if (error.graphQLErrors) {
        console.error('GraphQL ошибки:', error.graphQLErrors);
      }
      toastsStore.addErrorToast(this.t('common:toasts.error.restorePass'));
      return null;
    }
  }

  changeUserPassword = async (input: ChangePasswordInput): Promise<UserToken|null|undefined> => {
    try {
      const changePasswordNode = changePassword;
      const result = await apolloClient.mutate<{changePassword: UserToken}>({
        mutation: changePasswordNode,
        variables: { input },
      });
      runInAction(() => {
        this.currentUser = result.data?.changePassword;
      });
      return result.data?.changePassword;
    } catch {
      toastsStore.addErrorToast(this.t('common:toasts.error.restorePass'));
      return null;
    }
  }

  signOut =async () => {
    await apolloClient.query<{signOut: boolean}>({
      query: signOut,
    });
    runInAction(() => {
      this.currentUser = undefined;
      this.authRejected = true;
    });
  }

  getUserFollowing = async (username?: string, limit?: number): Promise<Follower[]> => {
    try {
      const res = await apolloClient.query<{getUserFollowing: Follower[]}>({
        query: getUserFollowing,
        variables: {
          input: {
            username: username ?? this.currentUser?.username,
            limit
          }
        }
      });
      return res.data.getUserFollowing ?? [];
    } catch {
      return [];
    }
  }

  getUserFollowers = async (username?: string, limit?: number): Promise<Follower[]> => {
    try {
      const res = await apolloClient.query<{getUserFollowers: Follower[]}>({
        query: getUserFollowers,
        variables: {
          input: {
            username: username ?? this.currentUser?.username,
            limit
          }
        }
      });
      return res.data.getUserFollowers ?? [];
    } catch {
      return [];
    }
  }

  getUserFriends = async (username?: string, limit?: number): Promise<UserPicture[]> => {
    const res = await apolloClient.query<{getUserFriends: UserPicture[]}>({
      query: getUserFriends,
        variables: {
          input: {
            username: username ?? this.currentUser?.username,
            limit
          }
        }
    });
    return res.data.getUserFriends ?? [];
  }

  follow = async (username: string): Promise<Follower|null> => {
    try {
      const res = await apolloClient.mutate<{follow: Follower}>({
        mutation: follow,
        variables: { username },
      })
      return res.data?.follow ?? null
    } catch {
      return null;
    }
  }

  unfollow = async (username: string): Promise<Follower|null> => {
    try {
      const res = await apolloClient.mutate<{unfollow: Follower}>({
        mutation: unfollow,
        variables: { username },
      })
      return res.data?.unfollow ?? null
    } catch {
      return null;
    }
  }

  changeUserPhone = async (input: ChangePhoneInput): Promise<UserToken|null|undefined> => {
    try {
      const result = await apolloClient.mutate<{changePhone: UserToken}>({
        mutation: changePhone,
        variables: { input }
      });
      runInAction(() => {
        this.currentUser = result.data?.changePhone;
      });
      return result.data?.changePhone;
    } catch (err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.changePhone'));
      return null;
    }
  }

  changePhoneConfirm = async (input: ChangePhoneConfirmInput): Promise<UserToken|null|undefined> => {
    try {
      const result = await apolloClient.mutate<{changePhoneConfirm: UserToken}>({
        mutation: changePhoneConfirm,
        variables: { input }
      });
      runInAction(() => {
        this.currentUser = result.data?.changePhoneConfirm;
      });
      return result.data?.changePhoneConfirm;
    } catch (err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.changePhone'));
      return null;
    }
  }

  changeUserEmail = async (input: ChangeEmailInput): Promise<UserToken|null|undefined> => {
    try {
      const result = await apolloClient.mutate<{changeEmail: UserToken}>({
        mutation: changeEmail,
        variables: { input }
      });
      runInAction(() => {
        this.currentUser = result.data?.changeEmail;
      });
      return result.data?.changeEmail;
    } catch (err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.changeEmail'));
      return null;
    }
  }

  changeEmailConfirm = async (input: ChangeEmailConfirmInput): Promise<UserToken|null|undefined> => {
    try {
      const result = await apolloClient.mutate<{changeEmailConfirm: UserToken}>({
        mutation: changeEmailConfirm,
        variables: { input }
      });
      runInAction(() => {
        this.currentUser = result.data?.changeEmailConfirm;
      });
      return result.data?.changeEmailConfirm;
    } catch (err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.changeEmail'));
      return null;
    }
  }

  changeExistsPassword = async (input: ChangeExistsPasswordInput): Promise<UserToken|null|undefined> => {
    try {
      const result = await apolloClient.mutate<{changeExistsPassword: UserToken}>({
        mutation: changeExistsPassword,
        variables: { input }
      });
      runInAction(() => {
        this.currentUser = result.data?.changeExistsPassword;
      });
      return result.data?.changeExistsPassword;
    } catch (err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.changePass'));
    }
  }

  addUserCity =async (cityId: string) => {
    try {
      const updateUserCityNode = updateUserCity;
      const result = await apolloClient.mutate<{updateUserCity: UserPicture}>({
        mutation: updateUserCityNode,
        variables: { cityId }
      });
      if (!this.currentUser) {
        return;
      }
      const currentUserCopy = {...this.currentUser};
      currentUserCopy.cityId = result.data?.updateUserCity.cityId ?? currentUserCopy.cityId;
      runInAction(() => {
        this.currentUser = currentUserCopy;
      });
      return result.data?.updateUserCity;
    } catch(err) {
      toastsStore.addErrorToast(this.t('common:toasts.error.setLocalCity'));
      return null;
    }
  }

  getUserFollowingUsers = async (username?: string, limit?: number): Promise<UserPicture[]> => {
    try {
      const res = await apolloClient.query<{getUserFollowingUsers: UserPicture[]}>({
        query: getUserFollowingUsers,
        variables: {
          input: {
            username: username ?? this.currentUser?.username,
            limit
          }
        }
      });
      return res.data.getUserFollowingUsers ?? [];
    } catch {
      return [];
    }
  }

  getUserFollowersUsers = async (username?: string, limit?: number): Promise<UserPicture[]> => {
    try {
      const res = await apolloClient.query<{getUserFollowersUsers: UserPicture[]}>({
        query: getUserFollowersUsers,
        variables: {
          input: {
            username: username ?? this.currentUser?.username,
            limit
          }
        }
      });
      return res.data.getUserFollowersUsers ?? [];
    } catch {
      return [];
    }
  }

  setLoading = (value: boolean) => {
    this.loading = value;
  }
}

export const userStore = new UserStore();
