import { gql } from "@apollo/client";

export const fileUploaded = gql`query fileUploaded($input: FileUploadedInput) {
    fileUploaded(input: $input){
        id
        key
        type
        url
        status
    }
}`;
