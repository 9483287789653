import { gql } from "@apollo/client";

export const checkMediaFiles = gql`query checkMediaFiles($input: CheckMediaFilesInput) {
    checkMediaFiles(input: $input){
        url
        key
        type
        converted
    }
}`;
