import { useTranslation } from "react-i18next";

export function useValidationRules() {

  const { t } = useTranslation('common');

  const commonRules = {
  name: {
    required: t('validationRules.emptyRequired'),
    pattern: {
      value: /^[A-Za-zА-Яа-я]{1,16}$/,
      message: t('validationRules.namePattern'),
    },
    minLength: {
      value: 1,
      message: t('validationRules.nameMinLen'),
    },
    maxLength: {
      value: 16,
      message: t('validationRules.nameMaxLen'),
    },
  },

  username: {
    required: t('validationRules.emptyRequired'),
    pattern: {
      value: /^(?=.*[A-Za-z])[A-Za-z0-9]{3,16}$/,
      message: t('validationRules.usernamePattern'),
    },
    minLength: {
      value: 3,
      message: t('validationRules.usernameMinLen'),
    },
    maxLength: {
      value: 16,
      message: t('validationRules.usernameMaxLen'),
    },
  },

  phone: {
    required: t('validationRules.emptyRequired'),
    pattern: {
      value: /^\+?[-1-9]\d{7,14}$/,
      message: t('validationRules.phonePattern'),
    },
    minLength: {
      value: 7,
      message: t('validationRules.phoneMinLen'),
    },
    maxLength: {
      value: 14,
      message: t('validationRules.phoneMaxLen'),
    },
  },

  password: {
    required: t('validationRules.emptyRequired'),
    pattern: {
      value: /^(?=.*\d)(?=.*[a-zа-я])(?=.*[A-ZА-Я])[0-9A-Za-zА-Яа-я!?@#$%^&*]{8,16}$/,
      message: t('validationRules.passwordPattern'),
    },
    minLength: {
      value: 8,
      message: t('validationRules.passwordMinLen'),
    },
    maxLength: {
      value: 16,
      message: t('validationRules.passwordMaxLen'),
    },
  },

  email: {
    pattern: {
      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      message: t('validationRules.emailPattern'),
    },
  },

  city: {
    pattern: {
      value: /^[A-Za-zА-Яа-я., ]{1,16}$/,
      message: t('validationRules.cityPattern'),
    },
  },

  dateOfBirth: {
    pattern: {
      value: /^[\d{2}/\d{2}/\d{4}]{10}$/,
      message: t('validationRules.dateOfBirthPattern'),
    },
  },
};

return {
  firstname: commonRules.name,
  lastname: commonRules.name,
  username: commonRules.username,
  phone: commonRules.phone,
  password: commonRules.password,
  passwordRepeat: commonRules.password,
  passwordCurrent: commonRules.password,
  city: commonRules.city,
  email: commonRules.email,
  dateOfBirth: commonRules.dateOfBirth
  }
}